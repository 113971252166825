import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Container from "../components/container";

class About extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Om siden" />
        <Hero title="Om siden" image />
        <Container>
          <p>Denne side drives som et hobby-projekt og har til formål at samle relevante produkter i en række kategorier i artikler. Det er dermed nemmere som forbruger, at vælge det rette produkt fordi man hurtigere kan få et overblik. Det må forventes, at siden over tid vil linke til produkter via en tredjepart, som dermed giver salgs-/klikprovision og derfor er siden ikke uvildig.</p>
        </Container>
      </Layout>
    )
  }
}

export default About

